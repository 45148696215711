(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.popup', 'kendo.filtermenu', 'kendo.menu', 'kendo.expansionpanel'], factory) :
    factory();
})((function () {
    var __meta__ = {
        id: "columnmenu",
        name: "Column Menu",
        category: "framework",
        depends: [ "popup", "filtermenu", "menu", 'expansionpanel' ],
        advanced: true
    };

    (function($, undefined$1) {
        var kendo = window.kendo,
            encode = kendo.htmlEncode,
            ui = kendo.ui,
            extend = $.extend,
            grep = $.grep,
            map = $.map,
            inArray = $.inArray,
            Comparer = kendo.data.Comparer,
            ACTIVE = "k-selected",
            ASC = "asc",
            DESC = "desc",
            CHANGE = "change",
            INIT = "init",
            OPEN = "open",
            SELECT = "select",
            STICK = "stick",
            UNSTICK = "unstick",
            POPUP = "kendoPopup",
            FILTERMENU = "kendoFilterMenu",
            MENU = "kendoMenu",
            EXPANSIONPANEL = "kendoExpansionPanel",
            NS = ".kendoColumnMenu",
            COLUMNHEADERATTR = "[scope='col']",
            Widget = ui.Widget;

        function trim(text) {
            return kendo.trim(text).replace(/&nbsp;/gi, "");
        }

        function toHash(arr, key) {
            var result = {};
            var idx, len, current;
            for (idx = 0, len = arr.length; idx < len; idx ++) {
                current = arr[idx];
                result[current[key]] = current;
            }
            return result;
        }

        function columnsGroupFilterHandler(column) {
            return this.columns.indexOf(column.title) >= 0 || this.columns.indexOf(column.field) >= 0;
        }

        function leafColumns(columns) {
            var result = [];

            for (var idx = 0; idx < columns.length; idx++) {
                if (!columns[idx].columns) {
                    result.push(columns[idx]);
                    continue;
                }
                result = result.concat(leafColumns(columns[idx].columns));
            }

            return result;
        }

        function attrEquals(attrName, attrValue) {
            return "[" + kendo.attr(attrName) + "='" + (attrValue || "").replace(/'/g, "\"") + "']";
        }

        function insertElementAt(index, element, container) {
            if (index > 0) {
                element.insertAfter(container.children().eq(index - 1));
            } else {
                container.prepend(element);
            }
        }

        function columnOccurrences(columns) {
            var columnDict = {};
            var signature;

            for (var i = 0; i < columns.length; i++) {
                signature = JSON.stringify(columns[i]);

                if (columnDict[signature]) {
                    columnDict[signature].push(i);
                } else {
                    columnDict[signature] = [i];
                }
            }

            return columnDict;
        }

        function oldColumnOccurrences(renderedListElements, checkBoxes) {
            var indexAttr = kendo.attr("index");
            var fieldAttr = kendo.attr("field");
            var columnDict = {};
            var signature;
            var columCheckbox;
            var index;
            var field;
            var title;

            for (var j = 0; j < renderedListElements.length; j++) {
                columCheckbox = checkBoxes.eq(j);
                index = parseInt(columCheckbox.attr(indexAttr), 10);
                field = columCheckbox.attr(fieldAttr);
                title = columCheckbox.attr("title");
                signature = field ? field : title;

                if (columnDict[signature]) {
                    columnDict[signature].push(index);
                } else {
                    columnDict[signature] = [index];
                }
            }

            return columnDict;
        }

        var ColumnMenu = Widget.extend({
            init: function(element, options) {
                var that = this,
                    columnHeader;

                options = options || {};
                options.componentType = options.componentType || "classic";
                Widget.fn.init.call(that, element, options);

                element = that.element;
                options = that.options;
                that.owner = options.owner;
                that.dataSource = options.dataSource;

                that.field = element.attr(kendo.attr("field"));
                that.title = element.attr(kendo.attr("title"));

                columnHeader = $(element.closest(COLUMNHEADERATTR));
                if (columnHeader.length) {
                    that.appendTo = columnHeader.find(options.appendTo);
                } else {
                    that.appendTo = $(options.appendTo);
                }

                that.link = that._createLink();

                that.wrapper = $('<div class="k-column-menu"/>');

                that._refreshHandler = that.refresh.bind(that);

                that.dataSource.bind(CHANGE, that._refreshHandler);
            },

            _init: function() {
                var that = this;

                that.pane = that.options.pane;
                if (that.pane) {
                    that._isMobile = true;
                }

                if (that._isMobile) {
                    that._createMobileMenu();
                } else {
                    that._createMenu();
                }

                that.owner._muteAngularRebind(function() {
                    that._angularItems("compile");
                });

                that._sort();

                that._columns();

                that._filter();

                that._lockColumns();

                that._stickyColumns();

                that.trigger(INIT, { field: that.field, container: that.wrapper });
            },

            events: [ INIT, OPEN, "sort", "filtering", STICK, UNSTICK ],

            options: {
                name: "ColumnMenu",
                messages: {
                    sortAscending: "Sort Ascending",
                    sortDescending: "Sort Descending",
                    filter: "Filter",
                    column: "Column",
                    columns: "Columns",
                    columnVisibility: "Column Visibility",
                    clear: "Clear",
                    cancel: "Cancel",
                    done: "Done",
                    settings: "Edit Column Settings",
                    lock: "Lock Column",
                    unlock: "Unlock Column",
                    stick: "Stick Column",
                    unstick: "Unstick Column",
                    setColumnPosition: "Set Column Position",
                    apply: "Apply",
                    reset: "Reset",
                    buttonTitle: "{0} edit column settings"
                },
                filter: "",
                columns: true,
                sortable: true,
                filterable: true,
                animations: {
                    left: "slide"
                },
                encodeTitles: false,
                componentType: "classic",
                appendTo: null
            },

            _createMenu: function() {
                var that = this,
                    options = that.options,
                    columns = that._ownerColumns(),
                    flattenMenuCols,
                    menuTemplate;

                if (that._hasGroups()) {
                    columns = that._groupColumns(columns);
                    flattenMenuCols = that._flattenMenuCols(columns);
                    if (flattenMenuCols.length !== that.owner.columns.length) {
                        that._syncMenuCols(flattenMenuCols, that.owner.columns);
                    }
                }

                if (that._isModernComponentType()) {
                    menuTemplate = kendo.template(modernTemplate);
                } else {
                    menuTemplate = kendo.template(template);
                }

                that.wrapper.html(menuTemplate({
                    uid: kendo.guid(),
                    ns: kendo.ns,
                    messages: options.messages,
                    sortable: options.sortable,
                    filterable: options.filterable,
                    columns: columns,
                    showColumns: options.columns,
                    hasLockableColumns: options.hasLockableColumns,
                    hasStickableColumns: options.hasStickableColumns,
                    encodeTitles: options.encodeTitles,
                    omitWrapAttribute: kendo.attr("omit-wrap")
                }));

                that.popup = that.wrapper[POPUP]({
                    anchor: that.link,
                    copyAnchorStyles: false,
                    open: that._open.bind(that),
                    activate: that._activate.bind(that),
                    deactivate: that._deactivate.bind(that),
                    close: function(e) {
                        if (that.menu) {
                            that.menu._closing = e.sender.element;
                        }
                        if (that.options.closeCallback) {
                            that.options.closeCallback(that.element);
                        }
                    }
                }).data(POPUP);

                if (that._isModernComponentType()) {
                    that.popup.element.addClass("k-grid-columnmenu-popup");
                    that._createExpanders();
                } else {
                    that.menu = that.wrapper.children()[MENU]({
                        orientation: "vertical",
                        closeOnClick: false,
                        open: function() {
                            that._updateMenuItems();
                        }
                    }).data(MENU);
                }
            },

            _createLink: function() {
                var that = this,
                    element = that.element,
                    appendTarget = that.appendTo.length ? element.find(that.appendTo) : element,
                    link = element.find(".k-header-column-menu"),
                    title = kendo.format(that.options.messages.buttonTitle, that.title || that.field);

                if (!link[0]) {
                    element.addClass("k-filterable");

                    link = appendTarget
                        .append('<a class="k-header-column-menu" href="#" aria-hidden="true" title="' +
                                    title + '"><span class="k-icon k-i-more-vertical"></span></a>')
                        .find(".k-header-column-menu");
                }

                link.attr("tabindex", -1)
                    .on("click" + NS, that._click.bind(that));

                return link;
            },

            _createExpanders: function() {
                var that = this;
                var options = that.options;
                var expanderOptions = {
                    expanded: false,
                    headerClass: "k-columnmenu-item",
                    useBareTemplate: true
                };

                that.wrapper.find(".k-columns-item")[EXPANSIONPANEL]($.extend(true, {}, expanderOptions,{
                    title: '<span class="k-icon k-i-columns"></span><span>' + options.messages.columns + '</span>'
                }));
                that.wrapper.find(".k-column-menu-filter")[EXPANSIONPANEL]($.extend(true, {}, expanderOptions,{
                    title: '<span class="k-icon k-i-filter"></span><span>' + options.messages.filter + '</span>'
                }));
                that.wrapper.find(".k-column-menu-position")[EXPANSIONPANEL]($.extend(true, {}, expanderOptions,{
                    title: '<span class="k-icon k-i-set-column-position"></span><span>' + options.messages.setColumnPosition + '</span>'
                }));
            },

            _syncMenuCols: function(menuCols, ownerCols) {
                var length = ownerCols.length;
                var ownerCol;
                var menuColsFields = menuCols.map(function(col) {
                    return col.field;
                });

                for (var i = 0; i < length; i++) {
                    ownerCol = ownerCols[i];
                    if (menuColsFields.indexOf(ownerCol.field) < 0) {
                        ownerCol.menu = false;
                    }
                }
            },

            _flattenMenuCols: function(cols) {
                var result = [];
                var length = cols.length;

                for (var i = 0; i < length; i++) {
                    if (cols[i].columns) {
                        result = result.concat(this._flattenMenuCols(cols[i].columns));
                    } else if (!cols[i].groupHeader) {
                        result.push(cols[i]);
                    }
                }
                return result;
            },

            _groupColumns: function(columns, nest) {
                var result = [];
                var groups = this.options.columns.groups;
                var length = groups.length;
                var i;
                var currGroup;
                var filterHandler;
                var group;
                var groupColumns;

                for (i = 0; i < length; i++) {
                    currGroup = groups[i];
                    filterHandler = columnsGroupFilterHandler.bind(currGroup);
                    group = { title: currGroup.title, groupHeader: true };
                    groupColumns = columns.filter(filterHandler);
                    result.push(group);

                    if (nest) {
                        group.columns = groupColumns;
                    } else {
                        result = result.concat(groupColumns);
                    }
                }

                return result;
            },

            _hasGroups: function() {
                return this.options.columns && this.options.columns.groups && this.options.columns.groups.length;
            },

            _isModernComponentType: function() {
                return this.options.componentType === 'modern' && !this._isMobile;
            },

            _deactivate: function() {
                if (this.menu) {
                    this.menu._closing = false;
                }
            },

            _createMobileMenu: function() {
                var that = this,
                    options = that.options,
                    columns = that._ownerColumns(),
                    groups,
                    flattenMenuCols;

                if (that._hasGroups()) {
                    groups = that._groupColumns(columns, true);
                    flattenMenuCols = that._flattenMenuCols(groups);
                    if (flattenMenuCols.length !== that.owner.columns.length) {
                        that._syncMenuCols(flattenMenuCols, that.owner.columns);
                    }
                }

                var html = kendo.template(mobileTemplate)({
                    ns: kendo.ns,
                    field: that.field,
                    title: that.title || that.field,
                    messages: options.messages,
                    sortable: options.sortable,
                    filterable: options.filterable,
                    columns: columns,
                    showColumns: options.columns,
                    hasLockableColumns: options.hasLockableColumns,
                    hasStickableColumns: options.hasStickableColumns,
                    hasGroups: that._hasGroups(),
                    groups: groups
                });

                that.view = that.pane.append(html);
                that.view.state = { columns: {} };

                that.wrapper = that.view.element.find(".k-column-menu");

                that.menu = new MobileMenu(that.wrapper.children(), {
                    pane: that.pane,
                    columnMenu: that
                });

                // The toggle animation of the switches should not propagate to the view
                that.menu.element.on("transitionend" + NS, function(e) {
                    e.stopPropagation();
                });

                var viewElement = that.view.wrapper && that.view.wrapper[0] ? that.view.wrapper : that.view.element;

                viewElement.on("click", ".k-header-done", function(e) {
                    e.preventDefault();

                    that.menu._applyChanges();
                    that.menu._cancelChanges(false);
                    that.close();
                });

                viewElement.on("click", ".k-header-cancel", function(e) {
                    e.preventDefault();

                    that.menu._cancelChanges(true);
                    that.close();
                });

                that.view.bind("showStart", function() {
                    var view = that.view || { columns: {} };

                    if (that.options.hasLockableColumns) {
                        that._updateLockedColumns();
                    }

                    if (that.options.hasStickableColumns) {
                        that._updateStickyColumns();
                    }

                    if (view.element.find(".k-sort-asc.k-selected").length) {
                        view.state.initialSort = "asc";
                    } else if (view.element.find(".k-sort-desc.k-selected").length) {
                        view.state.initialSort = "desc";
                    }
                });
            },

            _angularItems: function(action) {
                var that = this;
                that.angular(action, function() {
                    var items = that.wrapper.find(".k-columns-item input[" + kendo.attr("field") + "]").map(function() {
                        return $(this).closest("li");
                    });
                    var data = map(that._ownerColumns(), function(col) {
                        return { column: col._originalObject };
                    });
                    return {
                        elements: items,
                        data: data
                    };
                });
            },

            destroy: function() {
                var that = this;

                that._angularItems("cleanup");

                Widget.fn.destroy.call(that);

                if (that.filterMenu) {
                    that.filterMenu.destroy();
                }

                if (that._refreshHandler) {
                    that.dataSource.unbind(CHANGE, that._refreshHandler);
                }

                if (that.options.columns && that.owner) {
                    if (that._updateColumnsMenuHandler) {
                        that.owner.unbind("columnShow", that._updateColumnsMenuHandler);
                        that.owner.unbind("columnHide", that._updateColumnsMenuHandler);
                    }

                    if (that._updateColumnsLockedStateHandler) {
                        that.owner.unbind("columnLock", that._updateColumnsLockedStateHandler);
                        that.owner.unbind("columnUnlock", that._updateColumnsLockedStateHandler);
                    }
                }

                if (that.menu) {
                    that.menu.element.off(NS);
                    that.menu.destroy();
                }

                that.wrapper.off(NS);

                if (that.popup) {
                    that.popup.destroy();
                }

                if (that.view) {
                    that.view.purge();
                }

                that.link.off(NS);
                that.owner = null;
                that.wrapper = null;
                that.element = null;
            },

            close: function() {
                if (this.menu) {
                    this.menu.close();
                }
                if (this.popup) {
                    this.popup.close();
                    this.popup.element.off("keydown" + NS);
                }
            },

            _click: function(e) {
                var that = this;

                e.preventDefault();
                e.stopPropagation();

                var options = this.options;

                if (options.filter && this.element.is(!options.filter)) {
                    return;
                }

                if (!this.popup && !this.pane) {
                    this._init();
                } else {
                    that._updateMenuItems();
                }

                if (this._isMobile) {
                    this.pane.navigate(this.view, this.options.animations.left);
                } else {
                    this.popup.toggle();
                }
            },

            _updateMenuItems: function() {
                var that = this;
                if (that.options.columns) {
                    that._setMenuItemsVisibility();
                    if (!that.options.columns.sort && !that.options.columns.groups) {
                        that._reorderMenuItems();
                    } else {
                        that._updateDataIndexes();
                    }
                }
            },

            _setMenuItemsVisibility: function() {
                var that = this;

                that._eachRenderedMenuItem(function(index, column, renderedListElement) {
                    if (column.matchesMedia === false) {
                        renderedListElement.hide();
                    } else {
                        renderedListElement.show();
                    }
                });
            },

            _reorderMenuItems: function() {
                var that = this;

                that._eachRenderedMenuItem(function(index, column, renderedListElement, renderedList) {
                    if (renderedListElement[0] && renderedListElement.index() !== index) {
                        insertElementAt(index, renderedListElement, renderedList);
                    }
                });
                that._updateDataIndexes();
            },

            _updateDataIndexes: function() {
                var that = this;
                var renderedList = that._isMobile && that.view ?
                    $(that.view.element).find(".k-columns-item").children("ul") :
                    $(that.wrapper).find(".k-menu-group").first(),
                    mappedColumns = that._ownerColumns(true).map(function(x) {
                        return x.title || x.field;
                    });

                renderedList.find("span." + (this._isMobile ? "k-listgroup-form-field-wrapper" : "k-menu-link") +
                    " input").each(function(i) {
                    var columns;
                    var index;
                    if (that.options.columns.sort) {
                        columns = that._ownerColumns();
                        index = mappedColumns.indexOf(columns[i].title);
                        $(this).attr(kendo.attr("index"), index);
                    } else {
                        $(this).attr(kendo.attr("index"), i);
                    }
                });
            },

            _eachRenderedMenuItem: function(callback) {
                var that = this;
                var renderedListElement;
                var duplicateColumnIndex;
                var fieldValue;
                var currentColumn;
                var columns = grep(leafColumns(that.owner.columns), function(col) {
                    var result = true,
                        title = trim(col.title || "");

                    if (col.menu === false || (!col.field && !title.length)) {
                        result = false;
                    }

                    return result;
                }).map(function(col) {
                    return {
                         field: col.field,
                         title: col.title,
                         matchesMedia: col.matchesMedia
                       };
                });
                var renderedList = that._getRenderedList();
                var renderedListElements = that._getRenderedListElements(renderedList);
                var oldOccurances = oldColumnOccurrences(renderedListElements, renderedList.find("input[type=checkbox]"));
                var columnOccurrence = columnOccurrences(columns);
                var columnElements;

                for (var i = 0; i < columns.length; i++) {
                    currentColumn = columns[i];
                    fieldValue = currentColumn.field ? currentColumn.field : currentColumn.title;
                    duplicateColumnIndex = $.inArray(i, columnOccurrence[JSON.stringify(currentColumn)]);
                    columnElements = $();

                    for (var idx = 0; idx < oldOccurances[fieldValue].length; idx++) {
                        columnElements = columnElements.add(renderedListElements.eq(oldOccurances[fieldValue][idx]));
                    }
                    renderedListElement = columnElements.find(attrEquals("field", fieldValue)).closest(that._isModernComponentType() ? "label" : "li").eq(duplicateColumnIndex);
                    callback(i, currentColumn, renderedListElement, renderedList);
                }
            },

            _getRenderedList: function() {
                var that = this;

                if (that._isModernComponentType()) {
                    return $(that.wrapper).find('.k-columns-item');
                } else {
                    return that._isMobile && that.view ?
                    $(that.view.element).find(".k-columns-item").children("ul") :
                    $(that.wrapper).find(".k-menu-group").first();
                }
            },

            _getRenderedListElements: function(renderedList) {
                var that = this;

                if (that._isModernComponentType()) {
                    return renderedList.find('label');
                } else {
                    return renderedList.find("span." + (this._isMobile ? "k-listgroup-form-field-wrapper" : "k-menu-link"));
                }
            },

            _open: function() {
                var that = this,
                    instance, menuitem;

                $(".k-column-menu").not(that.wrapper).each(function() {
                    $(this).data(POPUP).close();
                });
                that.popup.element.on("keydown" + NS, function(e) {
                    var target = $(e.target);

                    if (that._isModernComponentType() && e.keyCode === kendo.keys.ENTER) {
                        target.click();
                    }
                    if (e.keyCode == kendo.keys.ESC) {
                        instance = kendo.widgetInstance(target.find("select"));

                        if (target.hasClass("k-picker") &&
                            instance &&
                            instance.popup.visible()) {
                                e.stopPropagation();
                                return;
                        }

                        menuitem = target.closest(".k-popup").closest(".k-menu-item");

                        if (menuitem.length > 0) {
                            menuitem.addClass("k-focus");

                            if (that.menu) {
                                that.menu.element.trigger("focus");
                            } else {
                                that.popup.element.find('[tabindex=0]').eq(0).trigger("focus");
                            }
                        }

                        target.closest(".k-popup").getKendoPopup().close();
                    }
                });

                if (that.options.hasLockableColumns) {
                    that._updateLockedColumns();
                }

                if (that.options.hasStickableColumns) {
                    that._updateStickyColumns();
                }
            },

            _activate: function() {
                if (this.menu) {
                    this.menu.element.trigger("focus");
                } else {
                    this.popup.element.find('[tabindex=0]').eq(0).trigger("focus");
                }

                this.trigger(OPEN, { field: this.field, container: this.wrapper });
            },

            _ownerColumns: function(omitSort) {
                var columns = leafColumns(this.owner.columns),
                    menuColumns = grep(columns, function(col) {
                        var result = true,
                            title = trim(col.title || "");

                        if (col.menu === false || (!col.field && !title.length)) {
                            result = false;
                        }

                        return result;
                    }),
                    result,
                    sort = this.options.columns.sort;

                result = map(menuColumns, function(col) {
                    return {
                        originalField: col.field,
                        field: col.field || col.title,
                        title: col.title || col.field,
                        hidden: col.hidden,
                        matchesMedia: col.matchesMedia,
                        index: inArray(col, columns),
                        locked: !!col.locked,
                        _originalObject: col,
                        uid: col.headerAttributes.id
                    };
                });

                if (sort && !omitSort) {
                    result.sort(Comparer.create({ field: "title", dir: sort }));
                }

                return result;
            },

            _sort: function() {
                var that = this;

                if (that.options.sortable) {
                    that.refresh();

                    if (that._isModernComponentType()) {
                        that.wrapper.on("click" + NS, ".k-sort-asc, .k-sort-desc", that._sortHandler.bind(that));
                    } else {
                        that.menu.bind(SELECT, that._sortHandler.bind(that));
                    }
                }
            },

            _sortHandler: function(e) {
                var that = this,
                    item = e.item ? $(e.item) : $(e.target),
                    dir;

                if (item.hasClass("k-sort-asc")) {
                    dir = ASC;
                } else if (item.hasClass("k-sort-desc")) {
                    dir = DESC;
                }

                if (!dir) {
                    return;
                }

                that._getSortItemsContainer(item).find(".k-sort-" + (dir == ASC ? DESC : ASC)).removeClass(ACTIVE);

                that._sortDataSource(item, dir);

                if (!that._isMobile) {
                    that.close();
                }
            },

            _getSortItemsContainer: function(item) {
                return this._isModernComponentType() ? item.parents('.k-columnmenu-item-wrapper').first() : item.parent();
            },

            _sortDataSource: function(item, dir) {
                var that = this,
                    sortable = that.options.sortable,
                    compare = sortable.compare === null ? undefined$1 : sortable.compare,
                    dataSource = that.dataSource,
                    idx,
                    length,
                    sort = dataSource.sort() || [];

                var removeClass = item.hasClass(ACTIVE) && sortable && sortable.allowUnsort !== false;

                dir = !removeClass ? dir : undefined$1;

                if (that.trigger("sort", { sort: { field: that.field, dir: dir, compare: compare } })) {
                    return;
                }

                if (removeClass) {
                    item.removeClass(ACTIVE);
                } else {
                    item.addClass(ACTIVE);
                }

                if (sortable.mode === "multiple") {
                    for (idx = 0, length = sort.length; idx < length; idx++) {
                        if (sort[idx].field === that.field) {
                            sort.splice(idx, 1);
                            break;
                        }
                    }
                    sort.push({ field: that.field, dir: dir, compare: compare });
                } else {
                    sort = [ { field: that.field, dir: dir, compare: compare } ];
                }

                dataSource.sort(sort);
            },

            _columns: function() {
                var that = this;

                if (that.options.columns) {

                    that._updateColumnsMenu();

                    that._updateColumnsMenuHandler = that._updateColumnsMenu.bind(that);

                    that.owner.bind(["columnHide", "columnShow"], that._updateColumnsMenuHandler);

                    that._updateColumnsLockedStateHandler = that._updateColumnsLockedState.bind(that);

                    that.owner.bind(["columnUnlock", "columnLock" ], that._updateColumnsLockedStateHandler);

                    if (that._isModernComponentType()) {
                        that.wrapper.on("click" + NS, '.k-columns-item .k-button:not(.k-button-solid-primary)', function() {
                            that._updateColumnsMenu();
                        });
                        that.wrapper.on("click" + NS, '.k-columns-item .k-button.k-button-solid-primary', that._applyColumnVisibility.bind(that));
                        that.wrapper.on("click" + NS, '.k-columns-item .k-checkbox', function() {
                            that._updateColumnsMenu(true);
                        });
                    } else {
                        that.menu.bind(SELECT, function(e) {
                            var item = $(e.item),
                                input,
                                column,
                                uidAttr = kendo.attr("uid"),
                                colIdx = 0,
                                columns = grep(leafColumns(that.owner.columns), function(col) {
                                    var result = true,
                                        title = trim(col.title || "");

                                    if (col.menu === false || (!col.field && !title.length)) {
                                        result = false;
                                    }

                                    return result;
                                });

                            if (that._isMobile) {
                                e.preventDefault();
                            }

                            if (!item.parent().closest("li.k-columns-item")[0]) {
                                return;
                            }

                            input = item.find(":checkbox");
                            if (input.attr("disabled")) {
                                return;
                            }

                            colIdx = columns.map(function(col) {
                                return col.headerAttributes.id;
                            }).indexOf(input.attr(uidAttr));
                            column = columns[colIdx];

                            if (column.hidden === true) {
                                that.owner.showColumn(column);
                            } else {
                                that.owner.hideColumn(column);
                            }
                        });
                    }
                }
            },

            _applyColumnVisibility: function() {
                var that = this;
                var fieldAttr = kendo.attr("field");
                var uidAttr = kendo.attr("uid");
                var checkboxes = that.wrapper.find(".k-columns-item input[" + fieldAttr + "]");
                var columnsInMenu = grep(leafColumns(this.owner.columns), function(col) {
                    var result = true,
                        title = trim(col.title || "");

                    if (col.menu === false || (!col.field && !title.length)) {
                        result = false;
                    }

                    return result;
                });
                var length = checkboxes.length;
                var idx;
                var colIdx;
                var checkbox;
                var column;

                that.owner.unbind("columnShow", that._updateColumnsMenuHandler);
                that.owner.unbind("columnHide", that._updateColumnsMenuHandler);

                for (idx = 0; idx < length; idx++) {
                    checkbox = $(checkboxes[idx]);
                    colIdx = columnsInMenu.map(function(col) {
                        return col.headerAttributes.id;
                    }).indexOf(checkbox.attr(uidAttr));
                    column = columnsInMenu[colIdx];

                   if (checkbox.is(":checked") && column.hidden) {
                       that.owner.showColumn(column);
                   } else if (checkbox.is(":not(:checked)") && !column.hidden) {
                       that.owner.hideColumn(column);
                   }
                }
                that.popup.close();
                that.owner.bind(["columnHide", "columnShow"], that._updateColumnsMenuHandler);
            },

            _updateColumnsMenu: function(omitCheckState) {
                var idx, length, current, checked, locked, that = this;
                var fieldAttr = kendo.attr("field"),
                    lockedAttr = kendo.attr("locked"),
                    uidAttr = kendo.attr("uid"),
                    columnIndexMap = {},
                    columnsCount = 0,
                    colIdx = 0;
                    omitCheckState = omitCheckState === true;
                    var columnsInMenu = grep(leafColumns(this.owner.columns), function(col, idx) {
                        var result = true,
                            title = trim(col.title || "");

                        if (col.menu === false || (!col.field && !title.length)) {
                            result = false;
                        }

                        if (result) {
                            columnIndexMap[idx] = columnsCount;
                            columnsCount++;
                        }

                        return result;
                    }),
                    visibleFields = grep(this._ownerColumns(), function(field) {
                        if (omitCheckState) {
                             return that.wrapper.find("[role='menuitemcheckbox'] [" + uidAttr + "='" + field.uid + "']").prop('checked');
                        }
                        return !field.hidden && field.matchesMedia !== false;
                    }),
                    visibleDataFields = grep(visibleFields, function(field) {
                        return field.originalField;
                    }),
                    lockedCount = grep(visibleDataFields, function(col) {
                        return col.locked === true;
                    }).length,
                    nonLockedCount = grep(visibleDataFields, function(col) {
                        return col.locked !== true;
                    }).length,
                    columnsNotInMenu = grep(this.owner.columns, function(col) {
                        return col.menu === false;
                    }),
                    hiddenColumnsNotInMenu = grep(columnsNotInMenu, function(col) {
                        return col.hidden;
                    });

                this.wrapper.find("[role='menuitemcheckbox']").attr("aria-checked", false);

                var checkboxes = this.wrapper
                    .find(".k-columns-item input[" + fieldAttr + "]")
                    .prop("disabled", false);

                if (!omitCheckState) {
                    checkboxes.prop("checked", false);
                }

                var switchWidget;

                for (idx = 0, length = checkboxes.length; idx < length; idx ++) {
                    current = checkboxes.eq(idx);
                    locked = current.attr(lockedAttr) === "true";
                    checked = false;
                    switchWidget = current.data("kendoSwitch");
                    colIdx = columnsInMenu.map(function(col) {
                        return col.headerAttributes.id;
                    }).indexOf(current.attr(uidAttr));

                    checked = omitCheckState ? current.prop('checked') : !columnsInMenu[colIdx].hidden && columnsInMenu[colIdx].matchesMedia !== false;
                    current.prop("checked", checked);

                    if (switchWidget) {
                        switchWidget.enable(true);
                        switchWidget.check(checked);
                    }

                    current.closest("[role='menuitemcheckbox']").attr("aria-checked", checked);

                    if (checked) {
                        if (lockedCount == 1 && locked) {
                            current.prop("disabled", true);

                            if (switchWidget) {
                                switchWidget.enable(false);
                            }
                        }

                        if ((columnsNotInMenu.length === 0 || (columnsNotInMenu.length === hiddenColumnsNotInMenu.length)) && nonLockedCount == 1 && !locked) {
                            current.prop("disabled", true);

                            if (switchWidget) {
                                switchWidget.enable(false);
                            }
                        }
                    }
                }
            },

            _updateColumnsLockedState: function() {
                var idx, length, current, column;
                var fieldAttr = kendo.attr("field");
                var lockedAttr = kendo.attr("locked");
                var columns = toHash(this._ownerColumns(), "field");
                var checkboxes = this.wrapper
                    .find(".k-columns-item input[type=checkbox]");

                for (idx = 0, length = checkboxes.length; idx < length; idx ++ ) {
                    current = checkboxes.eq(idx);
                    column = columns[current.attr(fieldAttr)];
                    if (column) {
                        current.attr(lockedAttr, column.locked);
                    }
                }

                this._updateColumnsMenu();
            },

            _filter: function() {
                var that = this,
                    widget = FILTERMENU,
                    options = that.options;

                if (options.filterable !== false) {

                    if (options.filterable.multi) {
                        widget = "kendoFilterMultiCheck";
                        if (options.filterable.dataSource) {
                            options.filterable.checkSource = options.filterable.dataSource;
                            delete options.filterable.dataSource;
                        }
                    }
                    that.filterMenu = that.wrapper.find(".k-filterable")[widget](
                        extend(true, {}, {
                            appendToElement: true,
                            dataSource: options.dataSource,
                            values: options.values,
                            field: that.field,
                            title: that.title,
                            change: function(e) {
                                if (that.trigger("filtering", { filter: e.filter, field: e.field })) {
                                    e.preventDefault();
                                }
                            },
                            componentType: that.options.componentType,
                            cycleForm: !that._isModernComponentType()
                        },
                        options.filterable)
                        ).data(widget);

                    if (that._isMobile) {
                        that.menu.bind(SELECT, function(e) {
                            var item = $(e.item);

                            if (item.hasClass("k-filter-item")) {
                                that.pane.navigate(that.filterMenu.view, that.options.animations.left);
                            }
                        });
                    }
                }
            },

            _lockColumns: function() {
                var that = this;

                if (that._isModernComponentType()) {
                    that.wrapper.on("click" + NS, ".k-lock, .k-unlock", that._lockableHandler.bind(that));
                } else {
                    that.menu.bind(SELECT, that._lockableHandler.bind(that));
                }
            },

            _lockableHandler: function(e) {
                var that = this;
                var item = e.item ? $(e.item) : $(e.target);

                if (item.hasClass("k-lock")) {
                    that.owner.lockColumn(that.field);
                    if (!that._isMobile) {
                        that.close();
                    }
                } else if (item.hasClass("k-unlock")) {
                    that.owner.unlockColumn(that.field);
                    if (!that._isMobile) {
                        that.close();
                    }
                }
            },

            _stickyColumns: function() {
                var that = this;

                if (that._isModernComponentType()) {
                    that.wrapper.on("click" + NS, ".k-stick, .k-unstick", that._stickableHandler.bind(that));
                } else {
                    that.menu.bind(SELECT, that._stickableHandler.bind(that));
                }
            },

            _stickableHandler: function(e) {
                var that = this;
                var item = e.item ? $(e.item) : $(e.target);
                var field = that.field;
                var columns = that.owner.columns;
                var column = grep(columns, function(column) {
                    return column.field == field || column.title == field;
                })[0];

                if (item.hasClass("k-stick")) {
                    that.owner.stickColumn(that.field);
                    that.trigger(STICK, { column: column });
                    if (!that._isMobile) {
                        that.close();
                    }
                } else if (item.hasClass("k-unstick")) {
                    that.owner.unstickColumn(that.field);
                    that.trigger(UNSTICK, { column: column });
                    if (!that._isMobile) {
                        that.close();
                    }
                }
            },

            _updateLockedColumns: function() {
                var field = this.field;
                var columns = this.owner.columns;
                var column = grep(columns, function(column) {
                    return column.field == field || column.title == field;
                })[0];

                if (!column) {
                    return;
                }

                var locked = column.locked === true;
                var length = grep(columns, function(column) {
                    return !column.hidden && ((column.locked && locked) || (!column.locked && !locked));
                }).length;
                var notLockable = column.lockable === false;

                var lockItem = this.wrapper.find(".k-lock").removeClass("k-disabled");
                var unlockItem = this.wrapper.find(".k-unlock").removeClass("k-disabled");

                if (locked || length == 1 || notLockable) {
                    lockItem.addClass("k-disabled");
                }

                if (!locked || length == 1 || notLockable) {
                    unlockItem.addClass("k-disabled");
                }

                this._updateColumnsLockedState();
            },

            _updateStickyColumns: function() {
                var field = this.field;
                var columns = this.owner.columns;
                var column = grep(columns, function(column) {
                    return column.field == field || column.title == field;
                })[0];

                if (!column) {
                    return;
                }

                var sticky = column.sticky === true;
                var stickable = column.stickable === true;
                var locked = column.locked === true;
                var length = grep(columns, function(column) {
                    return !column.hidden && ((column.locked && locked) || (!column.locked && !locked));
                }).length;

                var stickItem = this.wrapper.find(".k-stick").removeClass("k-disabled");
                var unstickItem = this.wrapper.find(".k-unstick").removeClass("k-disabled");

                if (sticky || !stickable || (locked && length === 1)) {
                    stickItem.addClass("k-disabled");
                }

                if (!sticky || !stickable) {
                    unstickItem.addClass("k-disabled");
                }
            },

            refresh: function() {
                var that = this,
                    sort = that.options.dataSource.sort() || [],
                    descriptor,
                    field = that.field,
                    idx,
                    length;

                that.wrapper.find(".k-sort-asc, .k-sort-desc").removeClass(ACTIVE);

                for (idx = 0, length = sort.length; idx < length; idx++) {
                   descriptor = sort[idx];

                   if (field == descriptor.field) {
                        that.wrapper.find(".k-sort-" + descriptor.dir).addClass(ACTIVE);
                   }
                }

                that.link[that._filterExist(that.dataSource.filter()) ? "addClass" : "removeClass"]("k-active");
            },

            _filterExist: function(filters) {
                var found = false;
                var filter;

                if (!filters) {
                    return;
                }

                filters = filters.filters;

                for (var idx = 0, length = filters.length; idx < length; idx++) {
                    filter = filters[idx];

                    if (filter.field == this.field) {
                        found = true;
                    } else if (filter.filters) {
                        found = found || this._filterExist(filter);
                    }
                }

                return found;
            }
        });

        /* ------------------------- MODERN TEMPLATE ------------------------- */

        function modernColumnsTemplateIterator(columns, encodeTitles, ns) {
            return columns.map(function (column) {
                if (column.groupHeader) {
                    return ("<span class=\"k-column-menu-group-header\"><span class=\"k-column-menu-group-header-text\">" + (column.title) + "</span></span>");
                } else {
                    return ("<label class=\"k-column-list-item\" role=\"menuitemcheckbox\" aria-checked=\"false\" " + (column.matchesMedia === false ? "style=\'display:none;\'" : "") + "><input class=\"k-checkbox k-checkbox-md k-rounded-md\" type=\"checkbox\" title=\"" + (encodeTitles ? encode(column.title) : column.title) + "\" data-" + ns + "field=\"" + (column.field.replace(/\"/g, "&#34;")) + "\" data-" + ns + "index=\"" + (column.index) + " data-" + ns + "locked=\"" + (column.locked) + "\" data-" + ns + "uid=\"" + (column.uid) + "\" /><span class=\"k-checkbox-label\">" + (encodeTitles ? encode(column.title) : column.title) + "</span></label>");
                }
            }).join("");
        }

        var SORTABLE_PARTIAL_MODERN = function (ref) {
            var messages = ref.messages;

            return ("<div class=\"k-columnmenu-item-wrapper\"><div><div class=\"k-columnmenu-item k-sort-asc\" tabindex=\"0\"><span class=\"k-icon k-i-sort-asc-sm\"></span>" + (messages.sortAscending) + "</div></div><div><div class=\"k-columnmenu-item k-sort-desc\" tabindex=\"0\"><span class=\"k-icon k-i-sort-desc-sm\"></span>" + (messages.sortDescending) + "</div></div></div>");
        };

        var COLUMNS_PARTIAL_MODERN = function (ref) {
            var columns = ref.columns;
            var messages = ref.messages;
            var encodeTitles = ref.encodeTitles;
            var ns = ref.ns;

            return ("<div class=\"k-columnmenu-item-wrapper\"><div><div class=\"k-columnmenu-item-content k-columns-item\"><div class=\"k-column-list-wrapper\"><div class=\"k-column-list\" role=\"menu\">" + (modernColumnsTemplateIterator(columns, encodeTitles, ns)) + "</div></div><div class=\"k-columnmenu-actions\"><button class=\"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base\" type=\"button\">" + (messages.reset) + "</button><button class=\"k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary\" type=\"button\">" + (messages.apply) + "</button></div></div></div></div>");
        };

        var LOCKABLE_COLUMNS_PARTIAL_MODERN = function (ref) {
            var messages = ref.messages;

            return ("<div class=\"k-columnmenu-item k-lock\" tabindex=\"0\"><span class=\"k-icon k-i-lock\"></span>" + (messages.lock) + "</div><div class=\"k-columnmenu-item k-unlock\" tabindex=\"0\"><span class=\"k-icon k-i-unlock\"></span>" + (messages.unlock) + "</div>");
        };

        var STICKABLE_COLUMNS_PARTIAL_MODERN = function (ref) {
            var messages = ref.messages;

            return ("<div class=\"k-columnmenu-item k-stick\" tabindex=\"0\"><span class=\"k-icon k-i-stick\"></span>" + (messages.stick) + "</div><div class=\"k-columnmenu-item k-unstick\" tabindex=\"0\"><span class=\"k-icon k-i-unstick\"></span>" + (messages.unstick) + "</div>");
        };

        var LOCK_STICK_COLUMNS_PARTIAL_MODERN = function (ref) {
            var hasLockableColumns = ref.hasLockableColumns;
            var hasStickableColumns = ref.hasStickableColumns;
            var messages = ref.messages;

            return ("<div class=\"k-columnmenu-item-wrapper\"><div class=\"k-column-menu-position\">" + (hasLockableColumns ? LOCKABLE_COLUMNS_PARTIAL_MODERN({ messages: messages }) : '') + "" + (hasStickableColumns ? STICKABLE_COLUMNS_PARTIAL_MODERN({ messages: messages }) : '') + "</div></div>");
        };

        var modernTemplate = function (ref) {
            var sortable = ref.sortable;
            var filterable = ref.filterable;
            var showColumns = ref.showColumns;
            var messages = ref.messages;
            var columns = ref.columns;
            var hasLockableColumns = ref.hasLockableColumns;
            var hasStickableColumns = ref.hasStickableColumns;
            var encodeTitles = ref.encodeTitles;
            var ns = ref.ns;

            return ("" + (sortable ? SORTABLE_PARTIAL_MODERN({ messages: messages }) : '') + "" + (showColumns ? COLUMNS_PARTIAL_MODERN({ columns: columns, messages: messages, encodeTitles: encodeTitles, ns: ns }) : '') + "" + (filterable ? '<div class="k-columnmenu-item-wrapper"><div class="k-columnmenu-item-content k-column-menu-filter"><div class="k-filterable"></div></div></div>' : '') + "" + (hasLockableColumns || hasStickableColumns ? LOCK_STICK_COLUMNS_PARTIAL_MODERN({ hasLockableColumns: hasLockableColumns, hasStickableColumns: hasStickableColumns, messages: messages }) : ''));
        };

        /* ------------------------- CLASSIC TEMPLATE ------------------------- */

        function classicColumnsTemplateIterator(columns, encodeTitles, ns, omitWrapAttribute) {
            return columns.map(function (column) {
                if (column.groupHeader) {
                    return ("<li class=\"k-column-menu-group-header\" " + omitWrapAttribute + "=\"true\" ><span class=\"k-column-menu-group-header-text\">" + (column.title) + "</span></li>");
                } else {
                    return ("<li role=\"menuitemcheckbox\" aria-checked=\"false\" " + (column.matchesMedia === false ? "style='display:none;'" : "") + "><input type=\"checkbox\" class=\"k-checkbox k-checkbox-md k-rounded-md\" title=\"" + (encodeTitles ? encode(column.title) : column.title) + "\" data-" + ns + "field=\"" + (column.field.replace(/\"/g,"&#34;")) + "\" data-" + ns + "index=\"" + (column.index) + "\" data-" + ns + "locked=\"" + (column.locked) + "\" data-" + ns + "uid=\"" + (column.uid) + "\"/>" + (encodeTitles ? encode(column.title) : column.title) + "</li>");
                }
            }).join("");
        }

        var SORTABLE_PARTIAL_CLASSIC = function (ref) {
            var messages = ref.messages;
            var showColumns = ref.showColumns;
            var filterable = ref.filterable;

            return ("<li class=\"k-item k-menu-item k-sort-asc\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-sort-asc-sm\"></span><span class=\"k-menu-link-text\">" + (messages.sortAscending) + "</span></span></li><li class=\"k-item k-menu-item k-sort-desc\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-sort-desc-sm\"></span><span class=\"k-menu-link-text\">" + (messages.sortDescending) + "</span></span></li>" + (showColumns || filterable ? '<li class="k-separator k-menu-separator" role="presentation"></li>' : ''));
        };

        var COLUMNS_PARTIAL_CLASSIC = function (ref) {
            var columns = ref.columns;
            var messages = ref.messages;
            var encodeTitles = ref.encodeTitles;
            var ns = ref.ns;
            var omitWrapAttribute = ref.omitWrapAttribute;
            var filterable = ref.filterable;
            var hasLockableColumns = ref.hasLockableColumns;
            var hasStickableColumns = ref.hasStickableColumns;

            return ("<li class=\"k-item k-menu-item k-columns-item\" aria-haspopup=\"true\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-columns\"></span><span class=\"k-menu-link-text\">" + (messages.columns) + "</span></span><ul>" + (classicColumnsTemplateIterator(columns, encodeTitles, ns, omitWrapAttribute)) + "</ul></li>" + (filterable || hasLockableColumns || hasStickableColumns ? '<li class="k-separator k-menu-separator" role="presentation"></li>' : ''));
        };

    var FILTERABLE_PARTIAL_CLASSIC = function (ref) {
        var messages = ref.messages;
        var hasLockableColumns = ref.hasLockableColumns;
        var hasStickableColumns = ref.hasStickableColumns;

        return ("<li class=\"k-item k-menu-item k-filter-item\" aria-haspopup=\"true\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-filter\"></span><span class=\"k-menu-link-text\">" + (messages.filter) + "</span></span><ul><li><div class=\"k-filterable\"></div></li></ul></li>" + (hasLockableColumns || hasStickableColumns ? '<li class="k-separator k-menu-separator" role="presentation"></li>' : ''));
        };

        var LOCKABLE_COLUMNS_PARTIAL_CLASSIC = function (ref) {
            var messages = ref.messages;
            var hasStickableColumns = ref.hasStickableColumns;

            return ("<li class=\"k-item k-menu-item k-lock\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-lock\"></span><span class=\"k-menu-link-text\">" + (messages.lock) + "</span></span></li><li class=\"k-item k-menu-item k-unlock\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-unlock\"></span><span class=\"k-menu-link-text\">" + (messages.unlock) + "</span></span></li>" + (hasStickableColumns ? '<li class="k-separator k-menu-separator" role="presentation"></li>' : ''));
        };

        var STICKABLE_COLUMNS_PARTIAL_CLASSIC = function (ref) {
            var messages = ref.messages;

            return ("<li class=\"k-item k-menu-item k-stick\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-stick\"></span><span class=\"k-menu-link-text\">" + (messages.stick) + "</span></span></li><li class=\"k-item k-menu-item k-unstick\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-unstick\"></span><span class=\"k-menu-link-text\">" + (messages.unstick) + "</span></span></li>");
        };

        var LOCK_STICK_COLUMNS_PARTIAL_CLASSIC = function (ref) {
            var messages = ref.messages;
            var hasLockableColumns = ref.hasLockableColumns;
            var hasStickableColumns = ref.hasStickableColumns;

            return ("<li class=\"k-item k-menu-item k-position-item\" aria-haspopup=\"true\"><span class=\"k-link k-menu-link\"><span class=\"k-icon k-i-set-column-position\"></span><span class=\"k-menu-link-text\">" + (messages.setColumnPosition) + "</span></span><ul>" + (hasLockableColumns ? LOCKABLE_COLUMNS_PARTIAL_CLASSIC({ messages: messages, hasStickableColumns: hasStickableColumns }) : '') + "" + (hasStickableColumns ? STICKABLE_COLUMNS_PARTIAL_CLASSIC({ messages: messages }) : '') + "</ul></li>");
        };

        var template = function (ref) {
            var uid = ref.uid;
            var sortable = ref.sortable;
            var filterable = ref.filterable;
            var showColumns = ref.showColumns;
            var messages = ref.messages;
            var columns = ref.columns;
            var hasLockableColumns = ref.hasLockableColumns;
            var hasStickableColumns = ref.hasStickableColumns;
            var encodeTitles = ref.encodeTitles;
            var ns = ref.ns;
            var omitWrapAttribute = ref.omitWrapAttribute;

            return ("<ul id=\"" + uid + "\">" + (sortable ? SORTABLE_PARTIAL_CLASSIC({ messages: messages, showColumns: showColumns, filterable: filterable }) : '') + "" + (showColumns ? COLUMNS_PARTIAL_CLASSIC({ columns: columns, messages: messages, encodeTitles: encodeTitles, ns: ns, omitWrapAttribute: omitWrapAttribute, filterable: filterable, hasLockableColumns: hasLockableColumns, hasStickableColumns: hasStickableColumns }) : '') + "" + (filterable ? FILTERABLE_PARTIAL_CLASSIC({ messages: messages, hasLockableColumns: hasLockableColumns, hasStickableColumns: hasStickableColumns }) : '') + "" + (hasLockableColumns || hasStickableColumns ? LOCK_STICK_COLUMNS_PARTIAL_CLASSIC({ messages: messages, hasLockableColumns: hasLockableColumns, hasStickableColumns: hasStickableColumns }) : '') + "\n</ul>");
        };

        /* ------------------------- MOBILE TEMPLATE ------------------------- */

        function mobileColumnsTemplateIterator(columns, groups, ns, hasGroups) {
            var result = "";

            if (hasGroups) {
                for (var i = 0; i < groups.length; i++) {
                    result += "<span class=\"k-list-group-header k-pb-1\">" + (encode(groups[i].title)) + "</span><ul class=\"k-listgroup k-listgroup-flush k-mb-4\">";

                    for (var idx = 0; idx < groups[i].columns.length; idx++) {
                        result += "<li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item\"><span class=\"k-listgroup-form-row\"><span class=\"k-listgroup-form-field-label k-item-title\">" + (groups[i].columns[idx].title) + "</span><span class=\"k-listgroup-form-field-wrapper\"><input type=\"checkbox\" title=\"" + (groups[i].columns[idx].title) + "\" data-" + ns + "field=\"" + (groups[i].columns[idx].field.replace(/\"/g,"&#34;")) + "\" data-" + ns + "index=\"" + (groups[i].columns[idx].index) + "\" data-" + ns + "uid=\"" + (groups[i].columns[idx].uid) + "\" data-" + ns + "locked=\"" + (groups[i].columns[idx].locked) + "\"/></span></span></li>\"";
                    }

                    result += "</ul>";
                }
            } else {
                result += "<ul class=\"k-listgroup k-listgroup-flush k-mb-4\">";

                for (var idx = 0; idx < columns.length; idx++) {
                    result += "<li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item\"><span class=\"k-listgroup-form-row\"><span class=\"k-listgroup-form-field-label k-item-title\">" + (columns[idx].title) + "</span><span class=\"k-listgroup-form-field-wrapper\"><input type=\"checkbox\" title=\"" + (columns[idx].title) + "\" data-" + ns + "field=\"" + (columns[idx].field.replace(/\"/g,"&#34;")) + "\" data-" + ns + "index=\"" + (columns[idx].index) + "\" data-" + ns + "uid=\"" + (columns[idx].uid) + "\" data-" + ns + "locked=\"" + (columns[idx].locked) + "\"/></span></span></li>";
                }

                result += "</ul>";
            }

            return result;
        }

        var SORTABLE_PARTIAL_MOBILE = function (ref) {
            var messages = ref.messages;

            return ("<li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-sort-asc\"><span class=\"k-link\"><span class=\"k-icon k-i-sort-asc-sm\"></span><span class=\"k-item-title\">" + (messages.sortAscending) + "</span></span></li><li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-sort-desc\"><span class=\"k-link\"><span class=\"k-icon k-i-sort-desc-sm\"></span><span class=\"k-item-title\">" + (messages.sortDescending) + "</span></span></li>");
        };

        var LOCKABLE_COLUMNS_PARTIAL_MOBILE = function (ref) {
            var messages = ref.messages;

            return ("<li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-lock\"><span class=\"k-link\"><span class=\"k-icon k-i-lock\"></span><span class=\"k-item-title\">" + (messages.lock) + "</span></span></li><li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-unlock\"><span class=\"k-link\"><span class=\"k-icon k-i-unlock\"></span><span class=\"k-item-title\">" + (messages.unlock) + "</span></span></li>");
        };

        var STICKABLE_COLUMNS_PARTIAL_MOBILE = function (ref) {
            var messages = ref.messages;

            return ("<li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-stick\"><span class=\"k-link\"><span class=\"k-icon k-i-stick\"></span><span class=\"k-item-title\">" + (messages.stick) + "</span></span></li><li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-unstick\"><span class=\"k-link\"><span class=\"k-icon k-i-unstick\"></span><span class=\"k-item-title\">" + (messages.unstick) + "</span></span></li>");
        };

        var FILTERABLE_PARTIAL_MOBILE = function (ref) {
            var messages = ref.messages;

            return ("<li id=\"" + (kendo.guid()) + "\" class=\"k-item k-listgroup-item k-filter-item\"><span class=\"k-link k-filterable\"><span class=\"k-icon k-i-filter\"></span><span class=\"k-item-title\">" + (messages.filter) + "</span><span class=\"k-select\"><span class=\"k-icon k-i-arrow-chevron-right\"></span></span></span></li>");
        };

        var COLUMNS_PARTIAL_MOBILE = function (ref) {
            var messages = ref.messages;
            var hasGroups = ref.hasGroups;
            var columns = ref.columns;
            var groups = ref.groups;
            var ns = ref.ns;

            return ("<li class=\"k-columns-item\"><span class=\"k-list-title\">" + (messages.columnVisibility) + "</span>" + (mobileColumnsTemplateIterator(columns, groups, ns, hasGroups)) + "</li>");
        };

        var mobileTemplate = function (ref) {
            var messages = ref.messages;
            var title = ref.title;
            var sortable = ref.sortable;
            var filterable = ref.filterable;
            var showColumns = ref.showColumns;
            var hasLockableColumns = ref.hasLockableColumns;
            var hasStickableColumns = ref.hasStickableColumns;
            var hasGroups = ref.hasGroups;
            var columns = ref.columns;
            var groups = ref.groups;
            var ns = ref.ns;

            return ("<div data-" + ns + "role=\"view\" class=\"k-grid-column-menu\"><div data-" + ns + "role=\"header\" class=\"k-header\"><a href=\"#\" class=\"k-header-cancel k-link\" title=\"" + (messages.cancel) + "\" aria-label=\"" + (messages.cancel) + "\"><span class=\"k-icon k-i-arrow-chevron-left\"></span></a>" + (messages.settings) + "<a href=\"#\" class=\"k-header-done k-link\" title=\"" + (messages.done) + "\" aria-label=\"" + (messages.done) + "\"><span class=\"k-icon k-i-check\"></span></a></div><div class=\"k-column-menu\"><ul class=\"k-reset\"><li><span class=\"k-list-title\">" + (messages.column) + ": " + title + "</span><ul class=\"k-listgroup k-listgroup-flush k-mb-4\">" + (sortable ? SORTABLE_PARTIAL_MOBILE({ messages: messages }) : '') + "" + (hasLockableColumns ? LOCKABLE_COLUMNS_PARTIAL_MOBILE({ messages: messages }) : '') + "" + (hasStickableColumns ? STICKABLE_COLUMNS_PARTIAL_MOBILE({ messages: messages }) : '') + "" + (filterable ? FILTERABLE_PARTIAL_MOBILE({ messages: messages }) : '') + "</ul></li>" + (showColumns ? COLUMNS_PARTIAL_MOBILE({ messages: messages, hasGroups: hasGroups, columns: columns, groups: groups, ns: ns }) : '') + "<li class=\"k-item k-clear-wrap\"><ul class=\"k-listgroup k-listgroup-flush\"><li class=\"k-listgroup-item\"><span class=\"k-link k-label k-clear\" title=\"" + (messages.clear) + "\" aria-label=\"" + (messages.clear) + "\">" + (messages.clear) + "</span></li></ul></li></ul></div></div>");
        };

        var MobileMenu = Widget.extend({
            init: function(element, options) {
                var that = this;

                Widget.fn.init.call(that, element, options);

                that._createCheckBoxes();

                that.element.on("click" + NS, "li.k-item:not(.k-separator):not(.k-disabled):not(:has(.k-switch))", "_click");
            },

            events: [ SELECT ],

            _click: function(e) {
                var that = this;

                if (!$(e.target).is("[type=checkbox]")) {
                    e.preventDefault();
                }

                if ($(e.target).hasClass("k-clear")) {
                    that._cancelChanges(true);

                    return;
                }

                if ($(e.target).hasClass("k-filterable")) {
                    that._cancelChanges(true);
                    that.trigger(SELECT, { item: e.currentTarget });

                    return;
                }

                that._updateSelectedItems(e.currentTarget);
            },

            _updateSelectedItems: function(el) {
                var that = this;
                var item = $(el);
                var state = that.options.columnMenu.view.state || { columns: {} };
                var id = item.prop("id");

                if (item.hasClass("k-filter-item")) {
                    return;
                }

                if (state[id]) {
                    state[id] = false;
                } else {
                    state[id] = true;
                }

                if (item.hasClass("k-sort-asc") || item.hasClass("k-sort-desc")) {
                    var dir;
                    var otherItem;
                    var otherItemId;

                    if (item.hasClass("k-sort-asc")) {
                        dir = "asc";
                        otherItem = that.element.find(".k-sort-desc");
                    } else {
                        dir = "desc";
                        otherItem = that.element.find(".k-sort-asc");
                    }

                    otherItemId = otherItem.prop("id");

                    if (dir === state.initialSort && !item.hasClass("k-selected")) {
                        state[id] = false;
                    }

                    if (state[otherItemId]) {
                        state[otherItemId] = false;
                    }

                    otherItem.removeClass(ACTIVE);
                }

                if (item.hasClass(ACTIVE)) {
                    item.removeClass(ACTIVE);
                } else {
                    item.addClass(ACTIVE);
                }
            },

            _cancelChanges: function(force) {
                var that = this;
                var menu = that.options.columnMenu;
                var view = menu.view;
                var state = view.state || { columns: {} };
                var columns = state.columns;

                that.element.find("." + ACTIVE).removeClass(ACTIVE);
                menu.refresh();

                if (force) {
                    var selectedItems = [];

                    for (var key in columns) {
                        if (columns.hasOwnProperty(key)) {
                            if (columns[key] === true) {
                                var item = view.element.find("#" + key);

                                selectedItems.push(item[0]);
                            }
                        }
                    }
                    // In order to use the columns hide/show validation,
                    // triggering the Select event must be done backwards
                    for (var i = selectedItems.length - 1; i >= 0; i--) {
                        that.trigger(SELECT, { item: selectedItems[i] });
                    }

                    if (menu.options.hasLockableColumns) {
                        menu._updateLockedColumns();
                    }

                    if (menu.options.hasStickableColumns) {
                        menu._updateStickyColumns();
                    }
                }

                that.options.columnMenu.view.state = { columns: {} };
            },

            _applyChanges: function() {
                var that = this;
                var view = that.options.columnMenu.view;
                var state = view.state || { columns: {} };

                for (var key in state) {
                    if (state.hasOwnProperty(key)) {
                        if (key !== "initialSort" && key !== "columns" && state[key] === true) {
                            var item = view.element.find("#" + key);

                            if (item.hasClass(ACTIVE)) {
                                item.removeClass(ACTIVE);
                            } else {
                                item.addClass(ACTIVE);
                            }

                            that.trigger(SELECT, { item: item[0] });
                        }
                    }
                }
            },

            _createCheckBoxes: function() {
                var that = this;

                that.element.find(".k-columns-item").find("[type='checkbox']").kendoSwitch({
                    messages: {
                        checked: "",
                        unchecked: ""
                    },
                    change: function(e) {
                        var item = e.sender.element.closest(".k-item");
                        var state = that.options.columnMenu.view.state || { columns: {} };
                        var id = item.prop("id");

                        if (state.columns[id]) {
                            state.columns[id] = false;
                        } else {
                            state.columns[id] = true;
                        }

                        that.trigger(SELECT, { item: item });
                    }
                });
            },

            _destroyCheckBoxes: function() {
                var that = this;
                var elements = that.element.find(".k-columns-item").find("[type='checkbox']");
                var switchWidget;

                for (var i = 0; i < elements.length; i++) {
                    switchWidget = elements.eq(i).data("kendoSwitch");

                    if (switchWidget) {
                        switchWidget.destroy();
                    }
                }
            },

            close: function() {
                this.options.pane.navigate("");
            },

            destroy: function() {
                var that = this;

                Widget.fn.destroy.call(that);

                that.element.off(NS);
                that._destroyCheckBoxes();
            }
        });

        ui.plugin(ColumnMenu);
    })(window.kendo.jQuery);

}));
